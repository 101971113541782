import React, { useState,useEffect } from "react";
import {
  Button,
  Container,
  Tab,
  Row,
  Nav,
  Col,
  Form,
  Dropdown,
  InputGroup,
  Card,
  Badge,
  Image,
} from "react-bootstrap";
import Header from "../Common/Header";
import NewSidebar from "../new-sidebar";
import Membershipsdeliverable from "./Membershipsdeliverable";
import Sidebar from "./Sidebar";
import ConfirmationModal from "./ConfirmationModal";

import {saveSiteDetails,updateCompanyLogoAndBrandColor,userInfo} from '../../helper/service';
 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import userImage from "../../images/dashboard/admin-img.png";
import editButton from "../../images/admin/edit-button.svg";
import NewLogo from '../../../src/images/new-logo.png';
import SuccessPopup from "./Sucesspopup";
import PreviewHub from "./PreviewHub";

const Branding = () => {
  const [brandColour, setBrandColour] = useState('#12aeb4');
  const [membershipPhoto, setMembershipPhoto] = useState("");
  const [img1,setImage1]=useState('');
  const [faviconLogo,setFaviconLogo] =useState('');
  const [falogo,setFaLogo]=useState('');
  const [footerLogo,setFooterLogo] = useState("");
  const [fologo,setFoLogo] = useState('');

  const [message, setMessage] = useState("");
  const [showWarningModal, setWarningModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [warningModalTitle, setWarningModalTitle] = useState("");
  const [isSuccess,setIsSuccess] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(()=>{
    getUserInfo();
  },[])

  const getUserInfo = () =>{
    const userId = localStorage.getItem('user_id');
    userInfo(userId).then(res => {
      const data = res.result.validUser
      
      setBrandColour(data.brandColor);

      setMembershipPhoto(data.company[0].logo ?? "");
      setImage1(data.company[0].logo ?? "");
      var falogo = data?.faviconLogo ?? '';
      var fologo = data?.footerLogo ?? '';

      setFaviconLogo(falogo);
      setFaLogo(falogo)

      setFooterLogo(fologo)
      setFoLogo(fologo);
    })
  }

  const hexColorFunc = (event) => {
    var value = event.target.value;
    setBrandColour(value);
    localStorage.setItem("bgcolor", value);
  };
  const onChangecolor = (event) => {
    event.preventDefault();
    var value = event.target.value;
    setBrandColour(value);
    localStorage.setItem("bgcolor", value);
  };
  const onImageChange = (event) => {
    if (
      event.target.files[0].type == "image/png" ||
      event.target.files[0].type == "image/jpg" ||
      event.target.files[0].type == "image/jpeg" ||
      event.target.files[0].type == "image/x-icon"
    ) {
      if (event.target.files && event.target.files[0]) {
        let img2 = URL.createObjectURL(event.target.files[0]);
        let img = event.target.files[0];
        if(event.target.name === "membershipPhoto")
        {
          setMembershipPhoto(img2);
          setImage1(img)
        }else if(event.target.name === "faviconLogo"){
          setFaviconLogo(img2);
          setFaLogo(img);
        }else if(event.target.name === "footerLogo"){
          setFooterLogo(img2);
          setFoLogo(img);
        }
      }
    } else {
      setMessage("Please upload valid image");
      setWarningModal(true);
      setModalType("error");
      setWarningModalTitle("Action Require!!!");
      event.target.value = "";
    }
  };
  const saveAppearance = async (e) =>{
    e.preventDefault()
    let data = {
      companyLogo: img1,
      bgcolor: brandColour,
    }
    var result =await updateCompanyLogoAndBrandColor(data);
    if(result.status === 200){
      setIsSuccess(true);
      setMessage("Site Apearance Updated Successfully");
      getUserInfo();
    }
  }
  const saveSiteLogos = async (e) =>{
    e.preventDefault()
    const data={
      falogo: falogo,
      fologo: fologo,
    }
    const res = await saveSiteDetails(data);
    if(res.status===200){
      setIsSuccess(true);
      setMessage("Site Logos Updated Successfully");
      getUserInfo();
    }
  }
  return (
    <>
      {isSuccess && <SuccessPopup message={message} closeSuccessModel = {()=>{setIsSuccess(false);setMessage('')}}/>}
      {showWarningModal && 
      <ConfirmationModal
        type={modalType}
        title={warningModalTitle}
        message={message}
        onConfirm={() => {
          setWarningModal(false);
          setMessage("");
          setModalType("");
          setWarningModalTitle("");
        }}
        onCancel={() => {
          setWarningModal(false);
          setMessage("");
          setModalType("");
          setWarningModalTitle("");
        }}
      />}
      <Header />
      <NewSidebar />
      <div className="content-wrapper admin-membership-setting bg-white">
        <Membershipsdeliverable />
        <section>
          <div className="container-fluid">
            <Tab.Container id="MembershipSettings" defaultActiveKey="Branding">
              <div className="d-flex gap-4">
                  <div
                    id="sidebar"
                    className={`${
                      !isExpanded ? "sidebar-expanded" : "sidebar-collapsed"
                    }`}
                  >
                    <div className="sidebar-header">
                      <button
                        id="toggleButton"
                        className="toggle-btn"
                        onClick={toggleSidebar}
                      >
                        {isExpanded ? (
                          <FontAwesomeIcon icon={faChevronRight} />
                        ) : (
                          <FontAwesomeIcon icon={faBars} />
                        )}
                      </button>
                    </div>

                    <div className="collapse-sidebar-content">
                      <Sidebar />
                    </div>
                  </div>
                <div>
                  <Tab.Content>
                    <Tab.Pane eventKey="Branding" className="CompanyDetails">
                      <div>
                        <h4 className="title mt-0">Branding</h4>
                        <form name="form" onSubmit={saveAppearance}>
                          <Row className="branding w-75">
                            <h4 className="tab-subtitle pl-0">
                              Edit Appearance
                            </h4>
                            <Col xl={6} lg={6} md={6} className="pl-0 mt-2">
                              <Form.Group controlId="logo">
                                <Form.Label style={{ color: "#6f6868" }}>
                                  Your Main Menu Logo <small>(200x200px)</small>
                                </Form.Label>
                                <Form.Label
                                  htmlFor="choose-file"
                                  className="upload-file"
                                >
                                  <Image
                                    src={editButton}
                                    className="edit-button-img cursor-pointer"
                                  ></Image>
                                </Form.Label>
                                <Form.Control
                                  type="file"
                                  id="choose-file"
                                  name="membershipPhoto"
                                  onChange={onImageChange}
                                />
                                <div
                                  id="preview_img"
                                  className="d-flex justify-content-center align-items-center"
                                  style={{ height: "94px" }}
                                >
                                  <div
                                    className="company-info d-flex justify-content-center align-items-center flex-wrap"
                                    style={{
                                      backgroundColor: brandColour,
                                      height: "83px",
                                    }}
                                  >
                                    {membershipPhoto ? (
                                      <Image
                                        height="65"
                                        width="65"
                                        src={membershipPhoto}
                                        className="mr-2 img-fluid"
                                      ></Image>
                                    ) : (
                                      <img
                                        height="40"
                                        width="40"
                                        className="mr-2 img-fluid"
                                        src={NewLogo}
                                      />
                                    )}
                                    <p className="company-name">
                                      Design Company <br></br>
                                      <small>YOUR MEMBERSHIP</small>
                                    </p>
                                  </div>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col xl={6} lg={6} md={6} className="mt-2">
                              <Form.Label style={{ color: "#6f6868" }}>
                                Select your brand Colour
                              </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={brandColour}
                                  onChange={hexColorFunc}
                                  placeholder="select your brand color"
                                  className="bg-white"
                                />
                                <InputGroup.Append>
                                  <Form.Control
                                    type="color"
                                    className="color-picker bg-white"
                                    value={brandColour}
                                    name="colorpick"
                                    onChange={onChangecolor}
                                  />
                                </InputGroup.Append>
                              </InputGroup>
                            </Col>
                            <button
                              className="btn btn-custom my-2"
                              type="submit"
                              style={{width: "270px"}}
                            >
                              Save
                            </button>
                          </Row>
                        </form>
                      </div>
                      <div>
                        <Row className="branding mt-4 w-100">
                          <div className="row preview-hub mb-1">
                            <h4 className="tab-subtitle pl-0 mb-2">
                              Preview of Member Hub
                            </h4>
                          </div>
                           <div className="preview-container">
                            <PreviewHub/>
                          </div>
                        </Row>
                      </div>
                      <div>
                        <Row className="branding my-4 w-75">
                          <form name="form" onSubmit={saveSiteLogos}>
                            <div className="col site-details pl-0">
                              <h4 className="tab-subtitle pl-0">
                                Site Details
                              </h4>
                              <Col
                                xl={6}
                                lg={6}
                                md={9}
                                className="pl-0 mt-2"
                              >
                                <Form.Group controlId="logo">
                                  <Form.Label style={{ color: "#6f6868" }}>
                                    Favicon Logo <small>(50x50px)</small>
                                  </Form.Label>
                                  <Form.Label
                                    htmlFor="choose-cover-file"
                                    className="upload-file"
                                  >
                                    <Image
                                      src={editButton}
                                      className="edit-button-img cursor-pointer"
                                    ></Image>
                                  </Form.Label>
                                  <Form.Control
                                    type="file"
                                    id="choose-cover-file"
                                    name="faviconLogo"
                                    onChange={onImageChange}
                                    ariaLabel="faviconLogo"
                                  />
                                  <div
                                    id="preview_img"
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ height: "94px" }}
                                  >
                                    <div className="company-info d-flex justify-content-center align-items-center bg-white h-75">
                                      {faviconLogo ? (
                                        <Image
                                          height="65"
                                          width="65"
                                          src={faviconLogo}
                                          className="mr-2 img-fluid"
                                        ></Image>
                                      ) : (
                                        <img
                                          height="40"
                                          width="40"
                                          className="mr-2 img-fluid"
                                          src={NewLogo}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col
                                xl={6}
                                lg={6}
                                md={9}
                                className="pl-0 mt-2"
                              >
                                <Form.Group controlId="logo">
                                  <Form.Label style={{ color: "#6f6868" }}>
                                    Footer Logo <small>(50x50px)</small>
                                  </Form.Label>
                                  <Form.Label
                                    htmlFor="choose-file1"
                                    className="upload-file"
                                  >
                                    <Image
                                      src={editButton}
                                      className="edit-button-img cursor-pointer"
                                    ></Image>
                                  </Form.Label>
                                  <Form.Control
                                    type="file"
                                    id="choose-file1"
                                    name="footerLogo"
                                    onChange={onImageChange}
                                  />
                                  <div
                                    id="preview_img"
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ height: "94px" }}
                                  >
                                    <div className="company-info d-flex justify-content-center align-items-center bg-white h-75 text-black">
                                      {footerLogo ? (
                                        <Image
                                          height="65"
                                          width="65"
                                          src={footerLogo}
                                          className="mr-2 img-fluid"
                                        ></Image>
                                      ) : (
                                        <img
                                          height="40"
                                          width="40"
                                          className="mr-2 img-fluid"
                                          src={NewLogo}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </Form.Group>
                                <button
                                className="btn btn-custom my-2 w-100 py-2"
                                type="submit"
                              >
                                Save
                              </button>
                              </Col>
                              
                            </div>
                          </form>
                        </Row>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </section>
      </div>
    </>
  );
};

export default Branding;
