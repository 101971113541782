import Chart from 'chart.js/auto';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { getActiveUsers } from '../helper/service'
const UsersChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Total Users',
        data: [],
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
      {
        label: 'Active Users',
        data: [],
        borderColor: 'purple',
        tension: 0.1, 
      }
    ],
  });

  const options = {
    scales: {
      y: {
        title: {
          display: false,
        },
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem("user_id");
        const bearer = "Bearer " + localStorage.getItem("token");
        const configData = {
          headers: { "content-type": "application/json", Authorization: bearer },
        };
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        const apiResponse = await getActiveUsers(userId, configData);;
        const usersCount = apiResponse.result.usersCount;
        const activeUsersCount = apiResponse.result.activeUsersCount;

        const monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const labels = Array.from({ length: 12 }, (_, index) => {
          const month = currentMonth - index;
          const year = currentYear + Math.floor((currentMonth - index - 1) / 12);
          const shortYear = year.toString().slice(-2);
          const monthName = monthNames[(month <= 0 ? month + 12 : month) - 1];

          return `${monthName} ${shortYear}`;
        }).reverse();

        const data = labels.map(label => {
          const [monthName, shortYear] = label.split(' ');
          const foundMonth = usersCount.find(obj => obj._id.month === monthNames.indexOf(monthName) + 1 && obj._id.year === parseInt(shortYear) + 2000);
          return foundMonth ? foundMonth.numberofUsers : 0;
        });
        const activeData = labels.map(label => {
          const [monthName, shortYear] = label.split(' ');
          const foundMonth = activeUsersCount.find(obj => obj._id.month === monthNames.indexOf(monthName) + 1 && obj._id.year === parseInt(shortYear) + 2000);
          return foundMonth ? foundMonth.activeNumberofUsers : 0;
        });
        const cumulativeData = data.map((value, index) => {
          return data.slice(0, index + 1).reduce((acc, curr) => acc + curr, 0);
        });
        const activeCumulativeData = activeData.map((value, index) => {
          return activeData.slice(0, index + 1).reduce((acc, curr) => acc + curr, 0);
        });


        setChartData({
          labels,
          datasets: [
            {
              label: 'Total Users',
              data: cumulativeData,
              borderColor: 'rgb(75, 192, 192,0.7)',
              backgroundColor: 'rgb(75, 192, 192,0.1)',
              tension: 0.1,
              borderWidth:9,
              order: 1,
            },
            {
              label: 'Active Users',
              data: activeCumulativeData,
              borderColor: 'rgba(128, 0, 128, 1)', 
              backgroundColor: 'rgba(128, 0, 128, 0.2)',
              tension: 0.1,
              fill: false,
              order: 0,
            }
          ],
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  return (
    <div>
      <Line options={options} data={chartData} />
    </div>
  );
};

export default UsersChart;

