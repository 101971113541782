import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import close from "../../images/dashboard/modal-close.svg";

const UserReportModalList = ({recipientUsers,sentEmailDate,emailMessage,handleCloseReportModal}) => {
    const [showReportModal, setShowReportModal] = useState(true);
    const hideUserReportModal = () => {
        setShowReportModal(false);
        handleCloseReportModal();
      }
  return (
   <>
   <Modal
          className="wis-modal modal-wide"
          show={showReportModal}
          onHide={hideUserReportModal}
          backdrop="static"
        >
          <Modal.Header className="justify-content-end align-items-center border-0 pb-5">
            <h3 className="modal-title w-100" id="wantToCreateModalLabel">
            {emailMessage}
            </h3>
            <button
              onClick={hideUserReportModal}
              type="button"
              className="close ml-5"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <img src={close} style={{ height: "50px", width: "50px" }} />
              </span>
            </button>
          </Modal.Header>
          <label className="ml-3">
          Email Sent Date: {sentEmailDate}
          </label>
          <Modal.Body className="text-center pb-5" style={{ borderRadius: " 2rem" }}>
            <div className="d-flex flex-wrap justify-content-center align-items-center text-center">
              <div className="audio ml-md-3 ml-xl-0 user-report-scroll">
              
                <table className="table mt-2" >
               
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {recipientUsers.map((item, ind) =>
                    (<tr key={ind}>
                      <td style={{ maxWidth: "12em", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{item._id}</td>
                      <td style={{ maxWidth: "12em", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{item.firstName} {item.lastName}</td>
                      <td style={{ maxWidth: "12em", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{item?.email}</td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
        </Modal></>
  )
}

export default UserReportModalList