import { useState, useEffect } from 'react';
import moment from 'moment';
import { getAllUpcomingEvents } from '../../helper/service';
import { Link } from "react-router-dom";

const UpcomingEvents = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    const getUpcomingEvents = async () => {
      setIsLoader(true);

      try {
        const data = await getAllUpcomingEvents();
        if (data.result.length > 0) {
          let upcomingEventsData = data.result.sort(function (a, b) {
            const firstEventDate = new Date(a.eventDate);
            const secondEventDate = new Date(b.eventDate);
            return firstEventDate - secondEventDate;
          });
          
          var arr1 = upcomingEventsData.map(function (evnt) {
            var originalUtcTimeStart = new Date(evnt.eventDate);
            originalUtcTimeStart.setUTCHours(originalUtcTimeStart.getUTCHours() + 1);
            var newUtcTimestart = originalUtcTimeStart.toISOString();
            var originalUtcTimeEnd = new Date(evnt.eventEndDate);
            originalUtcTimeEnd.setUTCHours(originalUtcTimeEnd.getUTCHours() + 1);
            var newUtcTimeEnd = originalUtcTimeEnd.toISOString();

            let eventDate = moment(newUtcTimestart).format('YYYY-MM-DD');
            let eventEndDate = moment(newUtcTimeEnd).format('YYYY-MM-DD');
            let startTime = moment(newUtcTimestart).format("HH:mm");
            let endTime = moment(newUtcTimeEnd).format("HH:mm");

            return (
              <div key={evnt._id} className="event-list">
                <div className="event-info">
                  <h5 className="event-title">
                    {evnt.name}
                  </h5>
                  <div className="d-flex">
                    <span className="event-time">{eventDate} {startTime} | {eventEndDate} {endTime}</span>
                  </div>
                </div>
              </div>
            );
          }, this);

          setUpcomingEvents(arr1);
        } else {
          setUpcomingEvents(["Currently, there are no upcoming events."]);
        }
      } catch (error) {
        console.error("Something went wrong. Couldn't fetch Upcoming Events. Please try again later.");
      } finally {
        setIsLoader(false);
      }
    };

    getUpcomingEvents();
  }, []);

  return (

    <>
      <div className="card-header">
        <h3 className="card-title">Upcoming Events</h3>

        <div className="card-tools">
          <Link to="/events" style={{ color: "#12aeb4" }}>
            View all <i className="fas fa-angle-right" />
          </Link>
        </div>
      </div>
      <div className="dashboard upcoming-event">
      <div className={upcomingEvents?.length > 3 ? 'events-scroll' : 'pr-2 border-right'}>
        {upcomingEvents}
        </div>
      </div>
    </>
  );
}
export default UpcomingEvents;