import React from "react";
import { Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";

const CompanyBanner = () => {
  return (
    <div className="notification-section">
      <p>
        Email messaging is not available!{" "}
        <Link to="/settings/company/" className="update-link">
          Please update company details.
        </Link>
      </p>
    </div>
  );
};

export default CompanyBanner;