import React, { Component } from "react";
import Header from '../components/Common/Header'
import NewSidebar from '../components/new-sidebar'
import { Link } from "react-router-dom";

const SubcribeStatus = () => {
  return (
    <div className="main-subs-expired-div">
      <Header />
      <NewSidebar />
      <div className="inner-subs-div">
        <div className="payment-failed">
          <img src={require("../images/wlogo.png")} />
          <p className="text">
            Wisdome gives memberships, courses and coaching programs access to
            many content sharing features....
          </p>
          <div className="failed-payment-div">
            <label>Hmmmm</label>
            <img src={require("../images/person.png")} />
            <label>That didn't work?</label>
            <label>Please buy subscription</label>
          </div>
          <Link to="/settings/account" className="back-to-subs">
            <img src={require("../images/arrowl.png")} />
            <label>GO TO SUBSCRIPTION</label>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SubcribeStatus
