import { create } from "zustand";

const useMembersPageStore = create((set) => ({
  openViewMemberModal: false,
  openUpdateMemberModal: false,
  openAddMemberModal: false,

  openNextAddMemberModal: false,
  memberData: null,
  memberSearchQuery: "",
  checkMemberData:null,

  openEmailModal:false,
  recipentMember:null,

  setMemberSearchQuery: (query) => set({ memberSearchQuery: query }),
  showViewMemberModal: (member, subsTier) => {
    member.subsTier = subsTier;
    set({ openViewMemberModal: true, memberData: member });
  },
  showUpdateMemberModal: (member) => {
    set({ openUpdateMemberModal: true, memberData: member });
  },

  showAddMemberModal: (memData) =>set({ openAddMemberModal: true,checkMemberData:memData }),
  showNextAddMemberModal: () => set({ openNextAddMemberModal: true }),
  hideViewMemberModal: () => set({ openViewMemberModal: false }),
  hideUpdateMemberModal: () => set({ openUpdateMemberModal: false }),
  hideAddMemberModal: () => set({ openAddMemberModal: false }),
  hideNextAddMemberModal: () => set({ openNextAddMemberModal: false }),

  showEmailModal: (member) => {
    set({ openEmailModal: true, recipentMember: member});
  },
  closeEmailModal:()=>set({openEmailModal:false})

}));

export default useMembersPageStore;
