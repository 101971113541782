import React, { useState, useEffect } from "react";
import BodyClassName from "react-body-classname";
import {
  Button, Col, Container, Form, Row, Tab
} from "react-bootstrap";
import { updateNewsData, userInfo } from "../../helper/service";
import Loader from "../../Loader";
import Header from "../Common/Header";
import SunRichTextEditor from "../RichTextEditor/SunRichTextEditor";
import NewSidebar from "../new-sidebar";
import Membershipsdeliverable from "./Membershipsdeliverable";
import Sidebar from "./Sidebar";
import SuccessPopup from "./Sucesspopup";
import ConfirmationModal from "./ConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars,faChevronRight } from "@fortawesome/free-solid-svg-icons";


const NewsSection = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [newsData, setNewsData] = useState("");
  const [limitExceedError, setLimitExceedError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showWarningModal,setWarningModal]= useState(false);
  const [modalType,setModalType]= useState('');
  const [modalTitle,setModalTitle]= useState('');
  const [message,setMdlMessage]= useState('');
  const colValue1 = 3, colValue2=9;
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const userId = localStorage.getItem("user_id");
    const data = await userInfo(userId);
    setNewsData(data.result.validUser.newsData);
  };

  const submitForm = async (event) => {
    event.preventDefault();
    setIsLoader(true);
   
    const data = {
      newsData: newsData
    };
    console.log(JSON.stringify(data).length);
    const res = await updateNewsData(data);
    if(res.status === 200)
    {
      setIsSuccess(true);
    }else{
      setWarningModal(true);
      setModalType('error');
      setModalTitle('Error!!');
      setMdlMessage(`${res.message}`);
    }
    setIsLoader(false);
  };
  const countDescriptionWords = (text) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = text;

    const images = tempDiv.querySelectorAll('img');
    images.forEach(img => {
      img.outerHTML = 'X';
    });
    const videos = tempDiv.querySelectorAll('iframe');
    videos.forEach(vid => {
      vid.outerHTML = 'X';
    })
    const audios = tempDiv.querySelectorAll('audio');
    audios.forEach(aud => {
      aud.outerHTML = 'X';
    })
    return tempDiv.innerText.trim().length;
  };
  const handlSunRichtextEditorChange = (content) => {
    const count = countDescriptionWords(content);
    if (count > 1000) {
      setWarningModal(true);
      setMdlMessage("No more than 1000 characters allowed");
      setModalTitle("Action Required");
      setModalType("warning");
      return;
    } else 
    if (count !== 0) {
      setNewsData(content);
    }
  };

  return (
    <BodyClassName>
      <div className="wrapper">
        {showWarningModal && <ConfirmationModal title={modalTitle} type={modalType} message={message} 
        onConfirm={() => { setModalTitle(''); setModalType(''); setMdlMessage(''); setWarningModal(false) }}
        onCancel={() => { setModalTitle(''); setModalType(''); setMdlMessage(''); setWarningModal(false) }}/>}
        {isLoader ? <Loader /> : <></>}
        {isSuccess ? <SuccessPopup message="News Section Updated Successfully." closeSuccessModel={()=>{setIsSuccess(false)}}/> : <></>}
        <Header />
        <NewSidebar />
        <div className="content-wrapper admin-membership-setting bg-white">
          <Membershipsdeliverable />
          <section>
            <Container fluid>
              <Tab.Container id="MembershipSettings" defaultActiveKey="NewsSectionSetting">
                <div className="d-flex gap-4">
                  <div id="sidebar" className={`${!isExpanded ? "sidebar-expanded" : "sidebar-collapsed"}`}>
                    <div className="sidebar-header">
                      <button id="toggleButton" className="toggle-btn" onClick={toggleSidebar} >
                        {isExpanded ? 
                          <FontAwesomeIcon icon={faChevronRight}/> 
                        : <FontAwesomeIcon icon={faBars} />}
                      </button>
                    </div>

                    <div className="collapse-sidebar-content">
                      <Sidebar/>
                    </div> 
                  </div>
                 <div>
                    <Tab.Content>
                      <Tab.Pane eventKey="NewsSectionSetting" className="WelcomePageSetting">
                        <div>
                          <h3 className="tab-title">Member News</h3>
                          <p className="tab-text">
                            News that will be displayed on member dashboard.
                          </p>
                          <form name="form" onSubmit={submitForm}>
                            <Row>
                              <Col md={12} style={{ height: 450 }}>
                                <Form.Group controlId="welcomeMessage">
                                  <Form.Label>
                                    Enter news to be displayed on member dashboard - Max 1000 characters
                                  </Form.Label>
                                  <SunRichTextEditor
                                    value={newsData}
                                    handlSunRichtextEditorChange={handlSunRichtextEditorChange}
                                  />
                                  {limitExceedError && (
                                    <Form.Label className="text-danger mt-2">
                                      1000 characters limit exceeded!
                                    </Form.Label>
                                  )}
                                </Form.Group>
                                <div className="d-flex justify-content-between flex-wrap">
                                  {/* <Button variant="custom" onClick={cancel}>Cancel</Button> */}
                                  <Button type="submit" variant="custom1">
                                    Save
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </Container>
          </section>
        </div>
      </div>
    </BodyClassName>
  );
};

export default NewsSection;
