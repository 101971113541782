import React from 'react';
import { Card } from 'react-bootstrap';
import './Gauge.css';

export default function Gauge(props) {
    return (
        <div className='gauge'>
            <Card className="plan mr-0">
                <Card.Body>
                    <div className="d-flex flex-column justify-content-between align-items-start">
                        <p className="plan-detail pt-1">{props.message}</p>
                        <div className="w-100 mt-1 current-plan-month-yearly">
                            <div className='row'>
                                <div className='col-lg-2 col-md-2 col-sm-6'>
                                    <span className="d-flex justify-content-between p-2 plan-name">
                                        {props.allowedText}
                                    </span>
                                </div>
                                <div className='col-lg-10 col-md-10 col-sm-6'>
                                    <input type='text' className='week-count form-control' value={`${props.allowed}`} readOnly />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-2 col-md-2 col-sm-6'>
                                    <span className="d-flex justify-content-between p-2 plan-name">
                                        {props.usedText}
                                    </span>
                                </div>
                                <div className='col-lg-10 col-md-10 col-sm-6'>
                                    <input type='text' className='week-count form-control' value={`${props.used}`} readOnly />
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}