const getGmtOffset = () => {
    const timeZone = localStorage.getItem("websiteTimeZone") || "";
    return getGMTOffsetFromTimeZone(timeZone);
}

const getGMTOffsetFromTimeZone = (timeZone) => {
    if (!timeZone) return "";

    const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone,
        timeZoneName: "short",
    });

    const parts = formatter.formatToParts(new Date());
    const gmtPart = parts.find((part) => part.type === "timeZoneName");

    return gmtPart ? gmtPart.value : "";
};

export { getGmtOffset, getGMTOffsetFromTimeZone };