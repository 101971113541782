import { React, useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import { fetchInvoices } from "../../helper/service";
import Loader from '../../Loader';
import Sidebar from "./Sidebar";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars,faChevronRight } from "@fortawesome/free-solid-svg-icons";
const SubadminInvoicesComponent = () => {
    const [invoices, setInvoices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const colValue1 = 3, colValue2=9;
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleSidebar = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        const fetchAndSetInvoices = async () => {
            setIsLoading(true);

            try {
                const data = await fetchInvoices();
                if (data.result) {
                    setInvoices(data.result);
                } else {
                    console.error("Unexpected data structure:", data);
                }
            } catch (error) {
                console.error("Error fetching invoices:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAndSetInvoices();
    }, []);
    useEffect(() => {
    }, [invoices]);

    return (
        <>
            {isLoading ? <Loader /> : <></>}
            
            <Container fluid className="mt-2">
                <Row >
                    <h3>Invoices</h3>
                </Row>
                <div className="d-flex gap-4">
                  <div id="sidebar" className={`${!isExpanded ? "sidebar-expanded" : "sidebar-collapsed"}`}>
                    <div className="sidebar-header">
                      <button id="toggleButton" className="toggle-btn" onClick={toggleSidebar} >
                        {isExpanded ? 
                          <FontAwesomeIcon icon={faChevronRight}/> 
                        : <FontAwesomeIcon icon={faBars} />}
                      </button>
                    </div>

                    <div className="collapse-sidebar-content">
                      <Sidebar/>
                    </div> 
                  </div>
                  <div className="memberShip-list w-100">
                        <div className="table-responsive mt-3">
                            <Table className='table'>
                                <thead>
                                    <tr>
                                        <th>Invoice ID</th>
                                        <th>Date/Time</th>
                                        <th>Status</th>
                                        <th>Amount</th>
                                        <th>Invoice Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoices.length > 0 ? invoices.map((invoice, index) => (
                                        <tr key={index}>
                                            <td>{invoice.invoiceId}</td>
                                            <td>{invoice.paidDate}</td>
                                            <td>{invoice.status}</td>
                                            <td>{`${invoice.amount} AUD`}</td>
                                            <td>
                                            <a href={invoice.invoiceLink} target="_blank" rel="noopener noreferrer">
                                                    View Invoice <OpenInNewIcon className="ml-1" />
                                                </a>
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">No invoices available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                {/* </Col> */}
                </div>
            </Container>
        </>
    )
}

export default SubadminInvoicesComponent;
