import axios from "axios";
import { commonUtil } from "../helper/CommonUtils";
const BASEURL = commonUtil.BASEURL();

let verifyEmail = async () => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "multipart/form-data", Authorization: bearer },
  };

  return await axios
    .post(BASEURL + "subadmin/verifyemail",
      {}, config)
    .then((res) => res)
    .catch((err) => {
      console.error("API Request Failed:", err.response || err.message);
      throw err;
    });
};


export { verifyEmail };
