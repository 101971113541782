import { Image } from "react-bootstrap";
import unsuccess from '../../images/audio/unsubscribe.svg';

export default function AttentionBanner(props) {
    const { message, link, linkText } = props;
    return (
        <div className="attention-banner text-center py-5">
            <Image src={unsuccess} className="success-img img-fluid d-block mx-auto" />
            <p className='title'>{message}</p>
            {link && linkText && <a href={link} className="btn btn-primary">{linkText}</a>}
        </div>
    )
}
