import React from 'react';
import {useNavigate } from 'react-router-dom';
import NewSidebar from '../new-sidebar';
import Header from '../Common/Header';
import InvoicesComponent from './InvoicesComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleLeft } from '@fortawesome/free-solid-svg-icons';
const MemberTransaction = () => {
    const navigate = useNavigate();
    return (
        <>
            <Header />
            <NewSidebar />
            <div className="content-wrapper admin-social-wall">
            <div className="row w-100 pt-1 ml-2">
              <span className="d-flex align-items-center fs-6 pointer w-25 p-0" style={{ color: '#12aeb4' }} onClick={() => navigate(-1)} >
                  <FontAwesomeIcon icon={faCircleLeft} color="#12aeb4" style={{ height: '25px', marginRight: '.5rem' }} /> Go Back
              </span>
            </div>
                <InvoicesComponent />
            </div>
        </>
    );
}

export default MemberTransaction;
