import React, { useState, useEffect } from "react";
import { moduleList, userInfo } from "../helper/service";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import UnsuccessPopup from "./Setting/UnsuccessPopup";
import { Image, Collapse } from "react-bootstrap";
import supportImage from "../images/sidebar/support.png";
import ConfirmationModal from "./Setting/ConfirmationModal";

const NewSidebar = () => {
  const [allowedModuleName, setAllowedModuleName] = useState([]);
  const [allowedModule, setAllowedModule] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [considerUpgrade, setConsiderUpgrade] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [is_redirect, setIsRedirect] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [showContentLibrary, setShowContentLibrary] = useState(false);
  const [imgs, setImages] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [showWarningModal, setWarningModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [faviconLogo, setFaviconLogo] = useState("");
  const [warningModalTitle, setWarningModalTitle] = useState("");
  const [isLogout, setLogout] = useState(false);

  useEffect(() => {
    getPartnerInfo();
  }, []);

  useEffect(() => {
    if (location.pathname == "/content-library") {
      setShowContentLibrary(true);
    }
  }, [location.pathname]);
  let link = document.querySelector("link[rel='icon']");
  if (faviconLogo) {

    link.href = faviconLogo
  }
  async function getPartnerInfo() {
    const userId = localStorage.getItem("user_id");
    await userInfo(userId).then((data) => {
      setFaviconLogo(data?.result?.validUser?.faviconLogo)
      setImages(
        data?.result?.validUser?.company &&
          data.result.validUser.company.length > 0 &&
          data.result.validUser.company[0].logo
          ? data.result.validUser.company[0].logo
          : data?.result?.validUser?.logo ?? ""
      );
      if (
        data?.result?.validUser?.subscriptionId &&
        data?.result?.validUser?.subscriptionId?.length > 0 &&
        data?.result?.validUser?.subscriptionId[0]?.subsId?.modules
      ) {
        setAllowedModule(
          data?.result?.validUser?.subscriptionId[0]?.subsId?.modules
        );
        setSubscriptionStatus(data?.result?.validUser?.subscriptionStatus);
      } else {
        setAllowedModule([]);
      }
    });
    getAllModule();
  }

  async function getAllModule() {
    var allowedModuleName = [];
    setIsLoader(true);
    await moduleList().then((data) => {
      var datas = data?.result?.map((subs, key) => {
        const checkModule = allowedModule.find(
          (mod) => mod?.moduleId == subs?._id
        );
        if (checkModule) {
          allowedModuleName.push(subs.name);
          return <></>;
        }
      });
      allowedModuleName.push("Social Wall");
      setIsLoader(false);
      setAllowedModuleName(allowedModuleName);
      // this.setState({ modulesData: datas, allowedModuleName: allowedModuleName, is_loader: false });
    });
  }

  function showAlert() {
    setIsSuccess(true);
  }

  function logout() {
    localStorage.clear();
    navigate("/login");
  }

  const checkStatusAndNavigateToPage = async (e) => {
    e.preventDefault();
    const clickedModule = e.target.innerText;
    const userId = localStorage.getItem("user_id");
    let allowedModuleNameArr = [];
    let subsStatus;
    const partnerInfo = userInfo(userId);
    const moduleData = moduleList();
    Promise.all([partnerInfo, moduleData]).then((result) => {
      result[1].result.map((subs, key) => {
        const checkModule =
          result[0]?.result?.validUser?.subscriptionId[0]?.subsId?.modules.find(
            (mod) => mod.moduleId == subs._id
          );
        if (checkModule) {
          allowedModuleNameArr.push(subs.name);
        }
      });

      var isFree = result[0]?.result?.validUser?.subscriptionId && result[0]?.result?.validUser?.subscriptionId?.length > 0 && result[0]?.result?.validUser?.subscriptionId[0]?.subsType === "Free"

      if (
        result[0].message == "SubAdmin Exist" &&
        allowedModuleNameArr.length > 0
      ) {
        subsStatus = result[0]?.result?.validUser?.subscriptionId?.[0]?.status;
        if (clickedModule == "Your HUB Map") {
          if (
            subsStatus == "Active"
          ) {
            navigate("/3-step");
          } else {
            navigate("/subcribe-status");
          }
        } else if (clickedModule == "Members") {
          if (
            subsStatus == "Active"
          ) {
            navigate("/members");
          } else {
            navigate("/subcribe-status");
          }
        } else if (clickedModule == "Content Library") {
          if (
            subsStatus == "Active"
          ) {
            if (allowedModuleNameArr.includes("Content Library")) {
              navigate('/content-library');
            } else {
              showAlert();
            }
          } else {
            navigate("/subcribe-status");
          }
        } else if (clickedModule == "Courses") {
          if (
            subsStatus == "Active"
          ) {
            if (allowedModuleNameArr.includes("Courses")) {
              navigate("/courses");
            } else {
              showAlert();
            }
          } else {
            navigate("/subcribe-status");
          }
        } else if (clickedModule == "Challenges") {
          if (
            subsStatus == "Active"
          ) {
            if (allowedModuleNameArr.includes("Challenges")) {
              navigate("/challenges-library");
            } else {
              showAlert();
            }
          } else {
            navigate("/subcribe-status");
          }
        } else if (clickedModule == "Events") {
          if (
            subsStatus == "Active"
          ) {
            if (allowedModuleNameArr.includes("Event")) {
              navigate("/events");
            } else {
              showAlert();
            }
          } else {
            navigate("/subcribe-status");
          }
        } else if (clickedModule == "Social Wall") {
          if (
            subsStatus == "Active"
          ) {
            if (allowedModuleNameArr.includes("Social Wall")) {
              navigate("/social-wall");
            } else {
              showAlert();
            }
          } else {
            navigate("/subcribe-status");
          }
        } else if (clickedModule == "Memberships") {
          if (
            subsStatus == "Active"
          ) {
            navigate("/3-step?membership=true");
          } else {
            navigate("/subcribe-status");
          }
        }
        else if (clickedModule == "Email") {
          if (
            subsStatus == "Active"
          ) {
            if (allowedModuleNameArr.includes("Email")) {
              navigate("/email");
            } else {
              isFree ? setConsiderUpgrade(true) : showAlert();
            }
          } else {
            navigate("/subcribe-status");
          }
        }
      }
      else {
        navigate("/subcribe-status");
      }
    });
  };

  const currentURL = window.location.pathname;
  var bgcolor = localStorage.getItem("bgcolor");

  var membershipName = localStorage.getItem("membershipName");

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {isSuccess ? (
        <UnsuccessPopup
          setIsSuccess={setIsSuccess}
          message="You are not subscribed to this module."
        />
      ) : (
        <></>
      )}
      {considerUpgrade ? (
        <UnsuccessPopup
          setIsSuccess={setConsiderUpgrade}
          message="This feature requires an upgrade."
          link="/settings/account" linkText="Upgrade Now"
        />
      ) : (
        <></>
      )}
      {showWarningModal && (
        <ConfirmationModal
          type={modalType}
          title={warningModalTitle}
          message={message}
          onConfirm={() => {
            setWarningModal(false);
            setMessage("");
            setModalType("");
            setWarningModalTitle("");
            if ({ isLogout }) {
              logout();
            }
          }}
          onCancel={() => {
            setWarningModal(false);
            setMessage("");
            setModalType("");
            setWarningModalTitle("");
          }}
        />
      )}
      {isLoader ? <Loader /> : <></>}

      <a
        id="arrowBar"
        className="nav-link collapse-sidebar"
        data-widget="pushmenu"
        href="#"
        role="button"
      >
        <i className="fas fa-bars" />
      </a>
      {/* Brand Logo */}
      <a href="/dashboard" className="brand-link">
        {/* company-info in className has been removed */}
        <div
          style={{
            display: "flex",
            backgroundColor: bgcolor,
            borderRadius: "4px",
            overflow: "hidden",
            padding: "0px",
          }}
        >
          {imgs && (
            <img
              src={imgs}
              className="brand-image mt-3 mb-3"
              alt="Admin Logo"
            />
          )}
          {/* <p className="company-name brand-text mt-3">{compName} {(compName ? <br /> : '')}<small>{membershipName}</small></p> */}
          {membershipName &&
            membershipName !== null &&
            membershipName !== "null" && (
              <p className="company-name brand-text mt-3">{membershipName}</p>
            )}
        </div>
      </a>
      <div className="sidebar left-main-sidebar">
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              {/* <a href="dashboard.html" className="nav-link active"> */}
              <NavLink
                to="/dashboard"
                className={
                  currentURL === "/dashboard" ? "nav-link active" : "nav-link"
                }
              >
                <span className="dashboard nav-icon" />
                <p>Dashboard</p>
              </NavLink>
              {/* </a> */}
            </li>
            <li className="nav-item">
              <button
                onClick={checkStatusAndNavigateToPage}
                className={
                  location.pathname === "/3-step" &&
                    !location.search.includes("membership")
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <span className="step nav-icon" />
                <p>Your HUB Map</p>
              </button>
            </li>

            <li className="nav-item">
              <NavLink
                to="/memberships"
                //  onClick={checkStatusAndNavigateToPage}
                className={
                  location.pathname === "/3-step" &&
                    location.search.includes("membership")
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <span className="membership nav-icon" />
                <p>Memberships</p>
              </NavLink>
            </li>

            <li className="nav-item">
              <button
                onClick={checkStatusAndNavigateToPage}
                className={
                  currentURL === "/members" ? "nav-link active" : "nav-link"
                }
              >
                <span className="member nav-icon" />
                <p>Members</p>
              </button>
            </li>

            <li className="nav-item">
              <NavLink
                to="/email"
                onClick={checkStatusAndNavigateToPage}
                className={
                  location.pathname === "/3-step" &&
                    location.search.includes("email")
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <span className="email nav-icon" />
                <p>Email</p>
              </NavLink>
            </li>

            <div className="border border-bottom border-2 my-2" />

            <li className="nav-item">
              <button
                onClick={checkStatusAndNavigateToPage}
                className={`nav-link content-bg ${currentURL === "/content-library" ? "active" : ""
                  }`}
              >
                <i className="library nav-icon" />
                <p>Content Library</p>
              </button>
            </li>

            <li className="nav-item">
              <button
                onClick={checkStatusAndNavigateToPage}
                className={
                  location.pathname === "/courses"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <span className="courses step nav-icon" />
                <p>Courses</p>
              </button>
            </li>

            <li className="nav-item">
              <button
                className={
                  currentURL === "/challenges-library"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={checkStatusAndNavigateToPage}
              >
                <span className="challenges nav-icon" />
                <p>Challenges</p>
              </button>
            </li>

            <li className="nav-item">
              <button
                className={
                  currentURL === "/social-wall" ? "nav-link active" : "nav-link"
                }
                onClick={checkStatusAndNavigateToPage}
              >
                <span className="socialwall nav-icon" />
                <p>Social Wall</p>
              </button>
            </li>

            <li className="nav-item">
              <button
                className={
                  currentURL === "/events" ? "nav-link active" : "nav-link"
                }
                onClick={checkStatusAndNavigateToPage}
              >
                <span className="event nav-icon" />
                <p>Events</p>
              </button>
            </li>

            <div className="border border-bottom border-2 my-2" />

            <li className="nav-item">
              <NavLink
                to="/settings/account"
                className={
                  currentURL === "company-details"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <span className="settings nav-icon" />
                <p>Settings</p>
              </NavLink>
            </li>
            <li className="nav-item mt-2">

              <a
                href="https://help.wisdome.com.au/"
                target="_blank"
                className="nav-link border-0 shadow-none"
              >
                <span className="support nav-icon" />
                <p className="support-img">
                  <Image src={supportImage} width="100%"></Image>
                </p>
              </a>
            </li>

            <li className="nav-item mb-4">
              <a
                href="#"
                className="nav-link d-flex align-items-center"
                onClick={() => {
                  setMessage("Are you sure you want to logout?");
                  setWarningModal(true);
                  setLogout(true);
                  setModalType("logout");
                  setWarningModalTitle("Logout Confirmation");
                }}
              >
                <span className="logout nav-icon" />
                <p>Log Out</p>
              </a>
            </li>

            {/* <li className="nav-item">
                  <a href="#" className="nav-link">
                    <img src={heart} className="nav-icon img-fluid" alt="sidebar-icon" />
                    <p>Favourites</p>
                  </a>
                </li> */}
            {/* <li className="nav-item">
                  <a href="#" className="nav-link">
                    <img src={folder} className="nav-icon img-fluid" alt="sidebar-icon" />
                    <p>
                      Info Portal
                      <i className="right fa fa-angle-right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Info Portal</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Info Portal</p>
                      </a>
                    </li>
                  </ul>
                </li> */}
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
    </aside>
  );
};

export default NewSidebar;
