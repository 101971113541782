import React from "react";

const SetEmailAddress = (props) => {
    const resetState =()=>{
    props.onClose();
    }
  return (
    <section>
      <div className="container-fluid email-setup wis-modal">
        <div className="row">
            <div className="col-xl-12 mb-4">
                <h2 className="title">Email Address Set Up</h2>
            </div>
        </div>
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-2">
                <div>
                    <div className="form-group w-50">
                    <label>
                        Add a new Email address 
                    </label>
                    <input
                        type="text"
                        value=''
                        placeholder="name@yourdomain.com"
                        required
                        maxLength={50}
                        name="subject"
                        className="form-control"
                        // onChange={(event) => {
                        //     setSubject(event.target.value);
                        // }}
                    />
                    </div>
                    <button type='submit' className="btn email-btn-custom">Save</button>
                </div>
                <div className="d-flex justify-content-between mt-4 form-control">
                    <table className="">
                        <tr>
                        <td className="">Email Address</td>
                        <td className="">Status</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div className="row my-4">
            <div className="d-flex justify-content-center gap-3">
                <button type='button' className="btn email-btn-custom bg-white" style={{color:'#12aeb4'}} onClick={()=>resetState()}>Cancel</button>
                <button type='submit' className="btn email-btn-custom">Save</button>
            </div>
        </div>
      </div>
    </section>
  );
};

export default SetEmailAddress;
